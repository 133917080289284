<template>
  <b-container
    class="mt-3"
    :class="$router.name == 'payment.split' ? 'px-0' : ''"
  >
    <b-row class="justify-content-center bg-white">
      <b-col
        :class="$router.name == 'payment.split' ? 'px-0' : ''"
        :cols="column"
      >
        <b-form>
          <b-row>
            <!-- Atas Nama -->
            <b-col cols="12" class="mb-2">
              <b-form-group
                class="custom__form--input mb-0"
                label="Atas Nama"
                label-for="atas-nama"
              >
                <v-select
                  v-model="formPayload.payment_customer_uuid"
                  label="title"
                  :options="options"
                  :reduce="(option) => option.label"
                  disabled
                  @search="onSearch"
                />
              </b-form-group>
            </b-col>

            <!-- Masukan Nominal -->
            <b-col v-if="$route.name == 'payment.split'" cols="12" class="mb-2">
              <b-form-group class="custom__form--input mb-0">
                <label for=""
                  >Masukan Nominal <span class="text-danger">*</span></label
                >
                <input
                  :id="`amount-piutang-${indexNominal}`"
                  class="focus-0 custom__input pl-1 w-100"
                  type="text"
                  :value="formPayload.cash_total_payment"
                  @input="getAmountPiutang"
                  @change="getAmountPiutang"
                  required
                />
                <p class="size14 mt-1">
                  Limit yang terpakai
                  <b>{{ formPayload.limit_piutang | formatAmount }}</b> / Limit
                  yang diberikan
                  <b>{{
                    formPayload.limit_piutang_available | formatAmount
                  }}</b>
                </p>
              </b-form-group>
            </b-col>

            <!-- Tempo -->
            <b-col cols="5" class="mb-2">
              <b-form-group
                class="custom__form--input mb-0"
                label="Tempo"
                label-for="saldo-deposit"
              >
                <b-form-input
                  id="saldo-deposit"
                  v-model="formPayload.piutang_tempo"
                  class="custom__input"
                  type="number"
                  placeholder="Tempo"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4" class="d-flex align-items-center">
              <span class="text-dark text-darken-5 fw-bold-600">Hari</span>
            </b-col>

            <!-- Button -->
            <b-col v-if="!isButtonHide" cols="12" class="mb-2 mt-2">
              <b-button
                :disabled="isLoading"
                class="btn-block size16 py-1 border-8"
                @click="
                  isSubmit ? submit() : $store.commit('formWizard/setStep', 2)
                "
              >
                Selanjutnya
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import {
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from "bootstrap-vue";
import { formatRupiah } from "@/auth/utils";
import vSelect from "vue-select";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.checkout.isLoading,
    }),
  },
  props: {
    isSubmit: {
      type: Boolean,
    },
    submit: {
      type: Function,
    },
    isButtonHide: {
      type: Boolean,
      default: false,
    },
    column: {
      type: Number,
      default: 5,
    },
    formData: {
      type: Object,
    },
    totalNominal: {
      type: Number,
    },
    totalAddtionalCost: {
      type: Number,
    },
    indexNominal: {
      type: Number,
    },
  },
  data() {
    return {
      selected: null,
      options: [],

      formPayload: {
        payment_customer_uuid: "",
        piutang_tempo: "",
        cash_total_payment: "",
        limit_piutang: null,
        limit_piutang_available: null,
      },
    };
  },
  setup() {
    return {
      formatRupiah,
    };
  },
  watch: {
    formData: {
      handler(value) {
        this.formPayload = value;
      },
      deep: true,
    },
    formPayload: {
      handler(value) {
        const payload = {
          ...value,
        };
        this.$emit("payloadPiutang", payload);
      },
      deep: true,
    },
    "formPayload.payment_customer_uuid": function (uuid) {
      this.getDetail(uuid);
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    this.formPayload.cash_total_payment =
      this.formatCurrency(
        parseFloat(this.$route.query.total) +
          this.totalAddtionalCost -
          this.totalNominal
      ) || "";
    this.formPayload = {
      ...this.formPayload,
      payment_customer_uuid: localStorage.getItem("customer_uuid"),
      piutang_tempo: localStorage.getItem("terms_of_payment_day"),
      // limit_piutang_available: this.formPayload.limitPiutangAvailable,
    };

    if (this.$route.query.data) {
      const decodeData = JSON.parse(decodeURIComponent(this.$route.query.data));
      setTimeout(() => {
        for (let index = 0; index < decodeData.length; index++) {
          const element = decodeData[index];
          if (
            this.indexNominal === index &&
            element.payment_method == "piutang"
          ) {
            this.formPayload = {
              ...element,
            };
          }
        }
      }, 500);
    }
  },
  methods: {
    formatCurrency(value) {
      if (isNaN(value)) {
        return "";
      }
      return value.toLocaleString("id-ID");
    },
    getAmountPiutang() {
      const rupiah = document.getElementById(
        `amount-piutang-${this.indexNominal}`
      );
      rupiah.value = this.formatRupiah(rupiah.value, "");
      this.formPayload.cash_total_payment = rupiah.value;
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.searchItem(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.options = [];
          this.getData();
          loading(false);
        }, 500);
      }
    },
    searchItem: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("customer/getData", {
          params: {
            search: escape(search),
          },
        })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          vm.options = [];
          response.data.data.data.forEach((item) => {
            vm.options.push({
              label: item.uuid,
              title: item.name,
            });
          });
          loading(false);
        });
    }, 300),
    async getDetail(uuid) {
      // this.isLoading = true
      this.$store
        .dispatch("customer/getDetail", {
          uuid,
        })
        .then((result) => {
          setTimeout(() => {
            this.options.unshift({
              label: result.data.data.uuid,
              title: result.data.data.name,
            });
            const option = this.getUniqueListBy(this.options, "label");
            this.options = option;
          }, 500);
          this.formPayload.limit_piutang =
            result.data.data.terms_of_payment_limit_used;
          this.formPayload.limit_piutang_available =
            result.data.data.terms_of_payment_limit;
          // this.isLoading = false
          console.log(this.formPayload.limit_piutang);
          console.log(this.formPayload.limit_piutang_available);
        })
        .catch((err) => {
          // this.isLoading = false
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getData() {
      // this.isLoading = true
      this.$store
        .dispatch("customer/getData", {
          params: {
            per_page: 30,
          },
        })
        .then((result) => {
          result.data.data.data.forEach((item) => {
            this.options.push({
              label: item.uuid,
              title: item.name,
            });
          });
          // this.isLoading = false
        })
        .catch((err) => {
          // this.isLoading = false
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";

.bg-danger {
  background-color: $danger-1 !important;
  border: 1px solid $danger-2 !important;
  color: $dark !important;
  border-radius: 8px;
}
</style>
